@import './tools.scss';

// Colors
$blueColor: #2d7dd2;
$darkBlueColor: #003049;
$dullOrangeColor: #f29e6f;
$grayColor: #8e98a7;
$lightBlueColor: #5c9ead;
$lightGrayColor: #eff1f3;
$orangeColor: #ff5e05;
$orangeColorRgba: rgba(126, 187, 149, 0.7);
$whiteColor: #ffffff;

body {
	margin: 0;
	padding: 0;
}