/*!
=========================================================
* Tools shortcuts for project
=========================================================
*/

// Colors
$blue: rgba(0, 122, 255, 0.7);;
$black: #000000;
$lightBlack: #113047;
$gray: #CDCDCD;
$lightDarkGray: #4F4F4F;
$darkGray: #7E7E7E;
$darkBlue: #113047;
$lightGray: #8DA7C4;
$white: #ffffff;

// Text Color
.ct-blue {
  color: rgba(0, 122, 255, 0.7);
}
.ct-black {
  color: #000000;
}
.ct-light-black {
  color: #113047;
}
.ct-dark-blue {
  color: #113047;
}
.ct-gray {
  color: #CDCDCD;
}
.ct-dark-gray {
  color: #7E7E7E;
}
.ct-light-dark-gray {
  color: #4F4F4F;
}
.ct-white {
  color: #ffffff !important;
}

// Background Color
.bg-blue {
  background-color: rgba(0, 122, 255, 0.7);
}
.bg-black {
  background-color: #000000;
}
.bg-light-black {
  background-color: #113047;
}
.bg-dark-blue {
  background-color: #113047;
}
.bg-gray {
  background-color: #CDCDCD;
}
.bg-dark-gray {
  background-color: #7E7E7E;
}
.bg-light-gray {
  background-color: #8DA7C4;
}
.bg-white {
  background-color: #ffffff;
}

// Font-Family
@font-face {
  font-family: 'Lato-Regular';
  src: url('../fonts/Lato/Lato-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato-Bold';
  src: url('../fonts/Lato/Lato-Bold.ttf') format('truetype');
}

// Font-Family Shorcut
.ff-LatoRegular {
  font-family: 'Lato-Regular' !important;
}
.ff-LatoBold {
  font-family: 'Lato-Bold' !important;
}

// Font-Size
.fs10 {
  font-size: 10px;
}
.fs12 {
  font-size: 12px;
}
.fs13 {
  font-size: 13px !important;
}
.fs14 {
  font-size: 14px;
}
.fs16 {
  font-size: 16px !important;
}
.fs18 {
  font-size: 18px;
}
.fs20 {
  font-size: 20px;
}
.fs22 {
  font-size: 22px;
}
.fs24 {
  font-size: 24px;
}
.fs26 {
  font-size: 26px;
}
.fs28 {
  font-size: 28px;
}
.fs30 {
  font-size: 30px;
}
.fs50 {
  font-size: 50px;
}

.fs65 {
  font-size: 65px;
}

//Text Transform
.tt-upper {
  text-transform: uppercase;
}
.tt-capitalize {
  text-transform: capitalize;
}

// Margin
.m-0-auto {
  margin: 0 auto;
}
.m-0 {
  margin: 0;
}
.ml-0-5-r {
  margin-left: 0.5rem;
}
.ml-1r {
  margin-left: 1rem;
}
.mr-1-5r {
  margin-right: 1.5rem;
}
.mr-2r {
  margin-right: 2rem;
}
.mt-0 {
  margin-top: 0;
}
.mt-1r {
  margin-top: 1rem;
}
.mt-1-5r {
  margin-top: 1.5rem !important;
}
.mt-2r {
  margin-top: 2rem;
}
.mt-3r {
  margin-top: 3rem;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-0-5r {
  margin-bottom: 0.5rem;
}
.mb-5p {
  margin-bottom: 5px;
}
.mb-1r {
  margin-bottom: 1rem;
}
.mb-1-5r {
  margin-bottom: 1.5rem !important;
}
.mb-2r {
  margin-bottom: 2rem;
}
.mb-2-5r {
  margin-bottom: 2.5rem;
}
.mb-3r {
  margin-bottom: 3rem !important;
}

// Width
.w-auto {
  width: auto !important;
}

.w-100 {
  width: 100% !important;
}

// Font Weight
.fw-bold {
  font-family: 'Lato-Bold';
  font-weight: bold;
}